.reloadContentIcon {
    animation-fill-mode: forwards;
    transform: rotate(0deg);
    transition: 1.2s all;
}

.reloadIconAnimation {
    animation: spinning-reload-icon 1.2s infinite linear;
}

@keyframes spinning-reload-icon {
    from { transform: rotate(0deg); }
    to { transform: rotate(-360deg); }
}