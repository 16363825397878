.custom-card {
    width: 200px;
    height: 200px;
    max-width: 275px;
    min-height: 200px;
    max-height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
}

.custom-card.new-model{
    text-align: center;
    cursor: pointer;
    opacity: 0.7;
}

.custom-card.new-model:hover{
    opacity: 1;
}

.new-model-icon{
    height: 100%;
}
.new-model{
    border:none;
    border-color: #3976ef;
}
.new-model .custom-card-content{
    margin: auto;
    width: 70%;
}

.custom-card.new-model .new-model-icon{
    width: 100%;
    height: 100%;
    color: #3976EF;
}

.custom-card-content {
    padding: 10px 25px 5px;
}

.custom-card-actions {
    padding: 0 25px 15px;
}

.cards_title {
    font-size: 16px;
    margin: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.custom-button {
    border-radius: 5px;
    width: 100%;
}

.custom-grid {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.custom-grid span {
    text-align: left;
}


  