.menuBlock {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
}

.menuScroll {
  position: relative;
  height: 80%;
  overflow: hidden;
  overflow-y: scroll;
}

.menu {
  width: 100%;
  background-color: white;
  /* height:calc(100vh - 64px); */
  border-right: 1px solid #ededf5;
  box-sizing: border-box;
}

.menu li, .menu span{
  font-size: 14px; 
}

.list {
  width: 100%;
}

.wideMenuWrapper:hover .menuItem {
  display: flex;
}

.menuItem {
  display: none;
  justify-content: end;
}

@media only screen and (max-width: 899px) {
  .activeMenuItem {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .ctaActions {
    display: block;
  }
  .ctaActions .cta {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    margin-left: 0;
  }
  .activeMenuItem {
    display: flex;
  }
}
