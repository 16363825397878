.container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eeeeeee3;
  border-radius: 2px;
}

.inner-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

.dots-loading {
  display:inline-block;
  font-family: monospace;
  clip-path: inset(0 3ch 0 0);
  animation: dots-loading 1s steps(4) infinite;
}

@keyframes dots-loading {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}
