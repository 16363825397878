.cursorWrapper {
  position: relative;
}

.cursor {
  display: inline-block;
  position: relative;
  color: rgba(0,0,0, .8);
  bottom: -2px;
}

.cursorAnimation {
  animation: blink 1s infinite;
  bottom: auto;
  top: 0;
}

@keyframes blink {
	0% {opacity: 0}
	49%{opacity: 0}
	50% {opacity: 1}
}
