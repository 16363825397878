.presetsHelperArea {
  padding: 20px;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.presetsHelperSelector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.presetsSelector {
  width: 40%;
}

.icon {
  font-size: 20px;
  line-height: 1.4;
  padding: 0 6px 3px 0;
  color: #007bff;
}

.firstText {
  color: #007bff
}

@media (max-width: 768px) {
  .presetsSelector{
    width: 100%;
  }
}