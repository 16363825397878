.bottom-shadow {
    /*position: absolute;*/
    /*bottom: 0px;*/
    width: 90%;
    height: 20px;
    /* box-shadow: inset 0px -20px 20px 20px #c7c7c72b; */
    background: radial-gradient(farthest-corner at 50% 20px, #000000 0%, transparent 70%);
    filter: blur(60px);
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.container {
    position: relative;
    /*max-height: MAX_HEIGHT;*/
    overflow: hidden;
}

.container-expanded {
    position: relative;
    max-height: 100%;
    overflow: hidden;
}

.inner-box {
    overflow: hidden;
    /*max-height: MAX_HEIGHT;*/
}

.flex-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.bottom-shadow {
    /* Add your bottom shadow styles here */
}

.toggle-button {
    position: absolute !important;
    bottom: 2px;
    font-size: 11px !important;
}
