.dots-loading {
    display: inline-block;
    font-family: monospace;
    clip-path: inset(0 3ch 0 0);
    animation: dots-loading 1s steps(4) infinite;
}

@keyframes dots-loading {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}

.personalized-hints-container {
    margin: 20px auto;
    text-align: center;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 15px;
    width: auto !important;
    max-width: 400px;
}

.personalized-hints-tools {
    display: flex;
    justify-content: space-between;
    max-width: 240px;
    padding: 0 20px;
    margin: auto;
    flex-flow: row wrap;
}

@media screen and (max-width: 900px) {
    .personalized-hints-container {
        width: 80%;
    }

    .personalized-hints-tools {
        margin-top: 10px;
    }
}

.wrapper {
    position: relative;
}

.helperTextWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.symbols {
    position: relative;
    right: 20px;
    padding: 2px 4px;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 11px;
    line-height: 1.1;
    margin-top: -12px;
    background: #fff;
}

.error {
    animation: rotate-shake .3s;
}

.exceeded {
    color: red;
    font-weight: bold;
}

@keyframes rotate-shake {
    0% {
        transform: rotate(5deg);
        color: rgba(0, 0, 0, 0.6);
    }

    25% {
        transform: rotate(-5deg);
        color: red;
    }

    50% {
        transform: rotate(5deg);
        color: rgba(0, 0, 0, 0.6);
    }

    75% {
        transform: rotate(-5deg);
        color: red;
    }

    100% {
        transform: rotate(5deg);
        color: rgba(0, 0, 0, 0.6);
    }
}