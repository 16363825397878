.container {
    border-radius: var(--container-border-radius);
    height: 100%;
    border: var(--container-border-width) solid var(--container-border-color);
    flex-flow: column !important;
    justify-content: space-between;
}

.border-bottom {
    border-bottom: var(--container-border-width) solid var(--container-border-color);
    flex: 0 1 auto !important;
}

.border-top {
    border-top: var(--container-border-width) solid var(--container-border-color);
}

.border-y {
    border-top: var(--container-border-width) solid var(--container-border-color);
    border-bottom: var(--container-border-width) solid var(--container-border-color);
}

.header-tools {
    padding: 5px 15px 5px 4px;
    flex: 0 1 auto !important;
    border-bottom: var(--container-border-width) solid var(--container-border-color);
}

.pagination {
    flex: 1 !important;
}

.last-item-no-border > li:last-child {
    border-bottom: unset;
}

.pagination-border {
    border-top: var(--container-border-width) solid var(--container-border-color);
}
