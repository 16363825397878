.preview-image{
    width: 100%;
    max-width: 285px;
    border-radius: 2px;
}

.full-screen-image {
    /*max-width: 100vw;*/
    /*max-height: 100vh;*/
}
  
  
  
  