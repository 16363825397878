.wrapper {
  width: 30px;
  height: 30px;
  position: relative;
}

.smallDot, .bigDot, .blue, .purple {
  position: absolute;
  border-radius: 50%;
}

.firstStage .blue {
  top: 26px;
  left: 20px;
}
.blue {
  width: 9px;
  height: 9px;
  background: #5678f6;
}

.firstStage .purple {
  top: 25px;
  left: -5px;
}
.purple {
  width: 12px;
  height: 12px;
  background: #6c70f7;
}

.smallDot {
  opacity: 1;
}

.secondStage .smallDot, .thirdStage .smallDot {
  opacity: 0;
}

.secondStage .smallDot, .secondStage .blue, .secondStage .purple {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 14px;
}
.secondStage .bigDot {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}

.thirdStage .smallDot {
  opacity: 0;
  transition: 300ms ease-in-out 300ms opacity;
}

.thirdStage .purple {
  left: 50%;
  top: 0;
  width: 14px;
  height: 14px;
  transform: translate(-50%, 0);
  background: #8469f8;
}

.thirdStage .blue {
  left: 17px;
  top: 16px;
  width: 14px;
  height: 14px;
  transform: translate(0, 0);
  background: #4f7bf7;
}

.bigDot {
  transform: translateY(-50%);
  top: 50%;
  left: -18px;
  width: 14px;
  height: 14px;
  background: #8868f9;
}

.bigDot.tick {
  border: 2px solid #4285ff;
}

.secondStage .bigDot {
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, #b45afc 15%, #4285ff 50%, #00b2f8 80%);
}

.thirdStage .bigDot {
  left: 0;
  top: 16px;
  width: 14px;
  height: 14px;
  transform: translate(0, 0);
  background: #a160fa;
}

.firstStage .dot1 {
  top: 50%;
  left: -37px;
}
.dot1 {
  width: 6px;
  height: 6px;
  background: #aa5dfb;
  transform: translateY(-50%);
}

.firstStage .dot2 {
  top: -3px;
  left: -25px;
}
.dot2 {
  width: 8px;
  height: 8px;
  background: #9f61fa;
}

.firstStage .dot3 {
  top: -15px;
  left: -7px;
}
.dot3 {
  width: 8px;
  height: 8px;
  background: #8c68f9;
}

.firstStage .dot4 {
  top: 27px;
  left: -29px;
}
.dot4 {
  width: 8px;
  height: 8px;
  background: #9065f9;
}

.firstStage .dot5 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dot5 {
  width: 10px;
  height: 10px;
  background: #5478f6;
}

.firstStage .dot6 {
  top: -3px;
  left: 2px;
}
.dot6 {
  width: 8px;
  height: 8px;
  background: #4f7bf7;
}

.firstStage .dot7 {
  top: 41px;
  left: -16px;
}
.dot7 {
  width: 6px;
  height: 6px;
  background: #766ef8;
}

.firstStage .dot8 {
  top: 52px;
  left: -3px;
}
.dot8 {
  width: 6px;
  height: 6px;
  background: #5379f7;
}

.firstStage .dot9 {
  top: 41px;
  left: 12px;
}
.dot9 {
  width: 6px;
  height: 6px;
  background: #3a85f8;
}

.firstStage .dot10 {
  top: -15px;
  left: 25px;
}
.dot10 {
  width: 8px;
  height: 8px;
  background: #517af6;
}

.firstStage .dot11 {
  top: -3px;
  left: 40px;
}
.dot11 {
  width: 8px;
  height: 8px;
  background: #0091f9;
}

.firstStage .dot12 {
  transform: translateY(-50%);
  top: 50%;
  left: 29px;
}
.dot12 {
  width: 6px;
  height: 6px;
  background: #268af8;
}

.firstStage .dot13 {
  transform: translateY(-50%);
  top: 50%;
  left: 57px;
}
.dot13 {
  width: 6px;
  height: 6px;
  background: #00a6fb;
}

.firstStage .dot14 {
  top: 27px;
  left: 41px;
}
.dot14 {
  width: 8px;
  height: 8px;
  background: #009ffa;
}

.thirdStage .dot1, .thirdStage .dot2, .thirdStage .dot3, .thirdStage .dot4, .thirdStage .dot5 {
  left: 50%;
  top: 0;
  width: 14px;
  height: 14px;
  transform: translate(-50%, 0);
}

.thirdStage .dot6, .thirdStage .dot7, .thirdStage .dot8, .thirdStage .dot9, .thirdStage .dot10 {
  left: 17px;
  top: 16px;
  width: 14px;
  height: 14px;
  transform: translate(0, 0);
}

.thirdStage .dot11, .thirdStage .dot12, .thirdStage .dot13, .thirdStage .dot14 {
  left: 0;
  top: 16px;
  width: 14px;
  height: 14px;
  transform: translate(0, 0);
}

.line1 {
  position: absolute;
  width: 32px;
  height: 2px;
  background: linear-gradient(90deg, #8c68f9 0%, #517af6 100%);
  top: -12px;
  left: -4px;
}
.line2 {
  position: absolute;
  width: 66px;
  height: 2px;
  background: linear-gradient(90deg, #8c68f9 0%, #0091f9 100%);
  top: 0px;
  left: -21px;
}
.line3 {
  position: absolute;
  width: 96px;
  height: 2px;
  background: linear-gradient(90deg, #8c68f9 0%, #0091f9 100%);
  top: 14px;
  left: -36px;
}
.line4 {
  position: absolute;
  width: 71px;
  height: 2px;
  background: linear-gradient(90deg, #8c68f9 0%, #0091f9 100%);
  top: 30px;
  left: -27px;
}
.line5 {
  position: absolute;
  width: 30px;
  height: 2px;
  background: linear-gradient(90deg, #8c68f9 0%, #0091f9 100%);
  top: 43px;
  left: -14px;
}

.line {
  opacity: 1;
}
.secondStage .line, .thirdStage .line {
  opacity: 0;
}

/* Animations */
.line {
  transition: 300ms ease-in-out 300ms opacity;
}
.secondStage .line {
  transition: 100ms ease-in-out  opacity;
}
.thirdStage .line {
  transition: 100ms ease-in-out opacity;
}

.bigDot {
  transition: 300ms ease-in-out all;
  z-index: 10;
}

.smallDot, .blue, .purple {
  transition: 300ms ease-in-out transform, 300ms ease-in-out left, 300ms ease-in-out top;
}

.thirdStage {
  animation: rotate 2s linear infinite;
}

.bouncing {
  animation: bouncing 1000ms ease-in-out infinite;
}

.tick {
  animation-duration: .2s;
  animation-name: tick;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.secondStage .bigDot, .secondStage .smallDot, .secondStage .blue, .secondStage .purple {
  /* transition: 300ms ease-in-out transform, 300ms ease-in-out left, 300ms ease-in-out top; */
}
.secondStage .bigDot{
  transition: 300ms ease-in-out all, 300ms ease-in-out 400ms width, 300ms ease-in-out 400ms height, 100ms ease 400ms opacity;
}
.secondStage .smallDot {
  transition: 300ms ease-in-out all, 100ms ease 400ms opacity;
}

.thirdStage .bigDot, .thirdStage .smallDot, .thirdStage .blue, .thirdStage .purple {
  transition: 300ms ease-in-out 400ms transform, 300ms ease-in-out 400ms left, 300ms ease-in-out 400ms top, 300ms ease-in-out all, 300ms ease-in-out height;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bouncing {
  0% {
    width: 48px;
    height: 48px;
  }
  50% {
    width: 52px;
    height: 52px;
  }
  100% {
    width: 48px;
    height: 48px;
  }
}

@keyframes tick {
  0% {
    width: 48px;
    height: 48px;
  }
  50% {
    width: 50px;
    height: 50px;
  }
  100% {
    width: 48px;
    height: 48px;
  }
}
