.rowIn {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.centerBtns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.centerBtnsInline {
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
}

.inputsRow {
  flex-grow: 1;
}

.error {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
