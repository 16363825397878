.one-line {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.one-line.renaming {
    margin-top: 1px;
    margin-bottom: 0px;
}

.chat-list-container {
    width: 100%;
    overflow-y: auto;
    /* max-height: calc(100vh - 200px); */
}

button.new-chat-btn {
    border-radius: 4px;
    width: 140px;
    font-size: 11px;
}

.one-line.menu li, .one-line.menu span{
  font-size: 12px; 
}


.focused-item {
    display: none;
}

.center-btns {
    width: 100%;
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

.center-btns-sm {
    width: 100%;
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

.listItem .actionCta {
    position: absolute;
    min-width: 30px;
    height: 30px;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    padding: 5px;
    top: 50%;
    margin-top: -15px;
}

.editCta {
    left: 0;
}

.deleteCta {
    right: 0;
}

.listItem .actionCtaWrap {
    display: none;
    position: relative;
    min-width: 60px;
    width: 60px;
    height: 20px;
}

.listItem:hover .actionCtaWrap {
    display: block;
}


.chat-puller-start {
    transform-origin: top center;
    animation: wobble 1.5s ease infinite;
}

@keyframes wobble {
    0% {
        transform: translateX(0%);
    }
    15% {
        transform: translateX(-1%);
    }
    30% {
        transform: translateX(5%);
    }
    45% {
        transform: translateX(-3%);
    }
    60% {
        transform: translateX(1%);
    }
    75% {
        transform: translateX(-1%);
    }
    100% {
        transform: translateX(0%);
    }
}


.attention-arrow {
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 15px;
    left: 10px;
    top: 10px;
}

/*.attention-arrow .arrow{left: 30%;}*/
.arrow {
    position: absolute;
    bottom: 0;
    margin-left: 0px;
    width: 12px;
    height: 12px;
    background-size: contain;
    top: 0;
    display: inline;
}

.attention-arrow-second {
    margin-left: 8px;
}

.arrow {
    font-size: 13px !important;
    fill: #b7b7b763 !important;
}

@keyframes bounceAnimation {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
    25% {
        opacity: 0;
        transform: translateX(10px) scale(0.9);
    }
    26% {
        opacity: 0;
        transform: translateX(-10px) scale(0.9);
    }
    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}

.point-animation {
    animation-name: bounceAnimation;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/*.arrow.primera.bounceAlpha {*/
/*  animation-name: bounceAlpha;*/
/*  animation-duration:1.4s;*/
/*  animation-delay:0.2s;*/
/*  animation-iteration-count:infinite;*/
/*  animation-timing-function:linear;*/
/*}*/


