.container {
  /* padding: 25px 40px 35px; */
  padding: 0 0 15px;
  /* max-height:calc(100vh - 64px);
  min-height:calc(100vh - 64px); */
  /* box-sizing: border-box; */
  /* overflow-y: auto; */
}

.title {
  text-transform: capitalize;
}

.inner-container {
  margin-top: 10px;
}

.version-info {
  position: absolute;
  bottom: -25px;
  right: 0;
  font-size: 10px;
  color: darkgray;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 0;
  }
  
  .version-info {
    bottom: -21px;
    font-size: 9px;
    right: 20px;
    left: 20px;
    text-align: center;
  }
}