.wrapper {
  position: relative;
}

.helperTextWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.symbols {
  position: relative;
  right: 20px;
  padding: 2px 4px;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 11px;
  line-height: 1.1;
  margin-top: -12px;
  background: #fff;
}

.error {
  animation: rotate-shake .3s;
}

@keyframes rotate-shake {
  0% {
    transform: rotate(5deg);
    color: rgba(0, 0, 0, 0.6);
  }
  
  25% {
    transform: rotate(-5deg);
    color: red;
  }

  50% {
    transform: rotate(5deg);
    color: rgba(0, 0, 0, 0.6);
  }

  75% {
    transform: rotate(-5deg);
    color: red;
  }
  
  100% {
    transform: rotate(5deg);
    color: rgba(0, 0, 0, 0.6);
  }
}
