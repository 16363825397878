.error {
  display: none;
  position: absolute;
  bottom: -14px;
  border-radius: 3px;
  left: 10px;
  color: #d32f2f;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.1;
}

.errorShowed {
  display: block;
  animation: rotate-shake .3s;
}

@keyframes rotate-shake {
  0% {
    transform: rotate(2deg);
  }
  
  25% {
    transform: rotate(-2deg);
  }

  50% {
    transform: rotate(2deg);
  }

  75% {
    transform: rotate(-2deg);
  }
  
  100% {
    transform: rotate(2deg);
  }
}

.symbols {
  position: absolute;
  right: 20px;
  padding: 2px 4px;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 11px;
  line-height: 1.1;
  margin-top: -12px;
  background: #fff;
}

.countError {
  animation: rotate-shake .3s;
}

@keyframes rotate-shake {
  0% {
    transform: rotate(5deg);
    color: rgba(0, 0, 0, 0.6);
  }
  
  25% {
    transform: rotate(-5deg);
    color: red;
  }

  50% {
    transform: rotate(5deg);
    color: rgba(0, 0, 0, 0.6);
  }

  75% {
    transform: rotate(-5deg);
    color: red;
  }
  
  100% {
    transform: rotate(5deg);
    color: rgba(0, 0, 0, 0.6);
  }
}

