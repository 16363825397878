.wrapper {
  max-height: 160px;
  overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
.wrapper::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background-color: #babac0;
  border: 2px solid #fff; 
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
