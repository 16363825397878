/* custom-md-editor.css */
.md-container {
  position: relative;
}

.custom-md-editor-button{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-bottom: solid 1px #ccc;
  border-left: solid 1px #ccc;
  border-right: none;
  border-top: none;

  background-color: var(--md-editor-background-color);
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 1px 1px 1px 1px;
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: wrap;
}

.wmde-markdown-var.w-md-editor.w-md-editor-show-edit.auto-size.w-md-editor-fullscreen .custom-md-editor-button-up {
  display: none !important;
}

.md-container *::-webkit-scrollbar {
  display: none;
}

.wmde-markdown-var.w-md-editor.w-md-editor-show-edit.auto-size{
  height: var(--md-editor-custom-height) !important;
  min-height:  var(--md-editor-custom-height) !important;
}

.wmde-markdown-var.w-md-editor.w-md-editor-show-edit.auto-size.w-md-editor-fullscreen {
  height: 100% !important;
}

.w-md-editor {
  --md-editor-font-family:'Inter',sans-serif !important;
}

.w-md-editor-text {
  letter-spacing: 0.7px;
  line-height: 1.5!important;
  font-weight: 400;
  min-height: var(--md-editor-custom-height) !important;
}
