.container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  padding: 0;
}

.msg-box {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
}

.msg-box.user {
  background-color: #00000005;
}

.inner-msg {
  width: 100%;
  align-items: center;
}

.inner-msg>*:first-child {
  margin-top: 0 !important;
}

.inner-msg>*:last-child {
  margin-bottom: 0 !important;
}

.inner-msg a,
p,
span {
  word-wrap: break-word;
}

.avatar {
  max-width: 30px;
  max-height: 30px;
  font-size: 11px !important;
}

.msg-box.ai>.avatar {
  /* background-color: #3976EF; */
  background-color: rebeccapurple;
}

.last-element-pointer {
  height: 1px;
  width: 100%;
}

.info-expand-container {
  width: 100%;
  padding: 0px 50px 0px 60px;
  position: relative;
  top: -15px;
}

.collapse-sources-container {
  width: 100%;
  margin-bottom: 20px;
}

.sources-structure {
  padding: 10px 40px;
  background-color: #eeeeee96;
  box-shadow: inset #80808087 0px 0px 25px -12px;
}

.description-text {
  font-size: 12px !important;
}

.expand-btn {
  cursor: pointer;
  margin: 10px 0 10px calc(24px + 8.333%);
  max-width: 25px;
  max-height: 12px;
  background-color: #eee;
  border-radius: 15px;
  text-align: center;
  
}

.expand-btn > span {
  position: relative;
  top: -10px;
}

.text-alignment {
  display: flex;
  /* align-items: center; */
}

.blinking-cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: black;
  animation: blink 1s step-start 0s infinite;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  white-space: nowrap;
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}


/* nice color: rebeccapurple */

@media only screen and (max-width: 900px) {
  .info-expand-container { 
    padding: 0;
    top: -52px;
    height: 0;
  }
  .expand-btn {
    margin-left: calc(8px + 12.333%);
    
  }
  /* .container {
    max-height: calc(100vh - 200px);
  } */
}