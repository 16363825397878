.button {
  transform: scale(.7);
  opacity: 0;
  animation-duration: 1s;
  animation-name: slide;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-direction: alternate;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid rgba(0,0,0, .1);
  cursor: pointer;
}

.cardContent {
  flex-grow: 1;
}

.expanded {
  min-height: 200px;
}

.expanded textarea {
  height: 180px !important;
  white-space: break-spaces !important;
  max-width: 100% !important;
}

.cta, .cta:hover {
  background-color: #3976EF !important;
  color: #fff !important;
}

@keyframes slide {
  from {
    transform: scale(.7);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes typewriter{
  from { width: 0; }
  to { width: 100%; }
}

.backBtn {
  position: absolute !important;
}

@media only screen and (max-width: 900px) {
  .backBtn {
    position: sticky !important;
  }
}
