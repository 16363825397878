.container { 
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  position: relative;
}

.trim {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 400px;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .trim {
    max-width: 235px;
  } 
}
