.hints-container {
    overflow-y: visible;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.hints-container-mobile {
    height: 100%;
    /*max-height: 80px;*/
    /*min-height: 60px;*/
    display: flex;
    overflow-x: auto;
    padding: 5px 0;
}

.hints-controls {
    color: #d2d2d2;
    opacity: 0.3;
    position: absolute;
    z-index: 1;
    cursor: pointer;
}

.hints-controls:hover {
    opacity: 0.9;
}

.left {
    left: -20px;
}

.right {
    right: -24px;
}

.hints-container-mobile::-webkit-scrollbar, .hints-container::-webkit-scrollbar {
    display: none;
}

.grouped-container > .hint-card:first-child {
    margin-left: 1px;
}

.grouped-container .hint-card:last-child {
    margin-right: 1px;
}

.hints-container-mobile .hint-card:first-child {
    margin-left: 1px;
}

.hints-container-mobile .hint-card:last-child {
    margin-right: 1px;
}

.hint-card {
    display: flex;
    margin: 0 8px 0;
    width: 100%;
    min-width: 200px;
    /*min-width: 80%;*/
    max-width: 275px;
    min-height: 60px;
}


.hints-container-mobile > .hint-card {
    min-width: 80%;
}

/*.hint-card:hover > */
/*.card-content:hover  {*/
/*    -webkit-line-clamp: initial;*/
/*    line-clamp: initial;*/
/*}*/

.new-chat-start {
    display: none;
    /*animation: disappear 1s ease-in-out forwards;*/
}

.welcome-window {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
}

@keyframes fall-animation {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        transform: translateY(100vh) rotate(360deg);
        opacity: 0;
    }
}

@keyframes tail {
    0% {
        width: 0;
    }
    30% {
        width: 100px;
    }
    100% {
        width: 0;
    }
}

@keyframes disappear {
    0% {
        /*transform: translateY(0) scale(1);*/
        opacity: 1;
        /*scale: 1;*/
    }
    /*20%{*/
    /*    transform: translateY(100px);*/
    /*    scale: 0.9;*/
    /*}*/
    /*40%{*/
    /*    transform: translateY(200px);*/
    /*    scale: 0.8;*/
    /*}*/
    /*60%{*/
    /*    transform: translateY(300px);*/
    /*    scale: 0.7;*/
    /*}*/
    100% {
        opacity: 0;
        /*transform: translateY(500px) scale(0.4);*/

    }
}

@keyframes shining {
    0% {
        width: 0;
    }
    50% {
        width: 30px;
    }
    100% {
        width: 0;
    }
}