.wrapper {
    position: relative;
}

.helperTextWrapper {
    display: flex;
    justify-content: space-between;
}

/* .symbols {
    margin-left: 8px;
    font-size: 0.8rem;
} */

.symbols {
    position: relative;
    right: 20px;
    padding: 2px 4px;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 11px;
    line-height: 1.1;
    margin-top: -12px;
    background: #fff;
  }

.error {
    color: red;
}