.wrapper {
  border: 1px solid rgba(0,0,0, .1);
  margin-top: 16px;
}

.mobileWrapper {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.actionsWrapper {
  flex: 1;
}

.line {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  font-size: 12px;
}

.bold {
  font-weight: 600;
}

.verticalLine {
  font-size: 12px;
}
.verticalLine div {
  margin-bottom: 5px;
}

.line:not(:last-child) {
  border-bottom: 1px solid rgba(0,0,0, .1);
}

.line div {
  width: 15%;
  text-align: center;
  padding: 10px 5px;
}

.textOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.line div:nth-child(1),
.line div:nth-child(2) {
  width: 30%;
}

.commingSoon {
  font-size: 20px;
  text-align: center;
}

.headerLine {
  background: rgba(0,0,0, .1);
}

.status {
  padding: 2px 3px;
}

.status:first-letter {
  text-transform: uppercase;
}

.actions {
  display: flex;
  gap: 3px;
  justify-content: center;
  color: rgba(0,0,0, .6);
}

.actions a {
  padding: 0;
  background: none;
}

.statusGreen {
  padding: 2px 12px;
  border-radius: 10px;
  color: #fff;
  background: #8bc34a;
  white-space: nowrap;
}

.statusWarning {
  background: #ff9800;
}

.statusGrey {
  background: #bdbdbd;
}
