.showMore {
  max-height: 150px;
  overflow: hidden;
  mask: linear-gradient(rgb(255, 255, 255) 85%, transparent);
}


.indicator {
  width: 100px;
  height: 60px;
  box-shadow: 1px 3px 6px rgba(0,0,0, .3);
}

.indicator:hover, .indicatorActive {
  border-radius: 8px !important;
  outline: 3px solid #0b57d0 !important;
}

.carouselCta {
  position: absolute !important;
  top: 50%;
  margin-top: -20px !important;
  color: #333 !important;
  width: 40px;
  min-width: 40px !important;
  border-radius: 20px !important;
  z-index: 1;
}

.carouselCtaNext {
  right: -20px;
}

.carouselCtaPrev {
  left: -20px;
}

.ctaBlock {
  background: #fff;
  padding: 15px 5% 5px;
  bottom: 0;
  z-index: 1;
}

.ctaBlock::before {
  content: "";
  display: block;
  position: absolute;
  box-shadow: 0 -3px 5px rgba(0, 0, 0, .06);
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
}

@media only screen and (max-width: 900px) {
  .indicator {
    width: 40px;
    min-width: 40px !important;
    height: 20px;
  }
}
