.ctaWrapper {
  align-self: flex-end;
  padding: 0 6px;
  margin-bottom: 27px;
}

.wrapperWide {
  padding: 0 24px;
  margin-bottom: 34px;
}

.cta {
  display: block;
  position: relative;
}

.cta .badge {
  position: absolute;
  border: 1px solid rgba(0,0,0, .3);
  font-size: 14px;
  background: #007bff;
  color: #fff;
  font-size: 10px;
  right: -2px;
  top: -2px;
  padding: 0 3px;
  border-radius: 5px;
}

.cta .badgeWide {
  font-size: 14px;
  right: -10px;
  top: -10px;
}

.ctaIn {
  display: flex;
  align-items: center;
  padding: 4px;
}

.ctaInWide {
  gap: 10px;
  border: 1px solid rgba(0,0,0, .2);
  padding: 8px 11px;
}

.icon {
  flex-shrink: 0;
}

.textBlock {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: rgba(0,0,0, .7);
  font-weight: 400;
}

.textBlock .topText {
  width: 100%;
  font-size: 11px;
  line-height: 1.2;
  margin-top: 2px;
}

.textBlock .bottomText {
  font-size: 15px;
  line-height: 1.2;
}
