.container {
 display: flex;
 flex-flow: column !important;
 justify-content: flex-start;
 align-items: flex-start;
 width: 100%;
 flex: 1;
 /*max-height: calc(100vh - 250px);*/
 padding: 0;
 overflow-y: auto;
}

.msg-box {
 width: 100%;
 padding: 20px 0px;
 display: flex;
 word-break: break-word;
 justify-content: center;
 /* justify-content: flex-start !important; */
}

.msg-box.user {
 background-color: #00000005;
}

.inner-msg {
 width: 100%;
 align-items: center;
 word-break: break-word;
}

.inner-msg>*:first-child {
 margin-top: 0 !important;
}

.inner-msg>*:last-child {
 margin-bottom: 0 !important;
}

.inner-msg a,p,span {
   word-wrap: break-word;
} 

.avatar {
 max-width: 30px;
 max-height: 30px;
 font-size: 11px !important;
}

.msg-box.ai > .avatar {
 /* background-color: #3976EF; */
 background-color: rebeccapurple;
}

.cursor {
}

/* nice color: rebeccapurple */

@media only screen and (max-width: 900px) {
 /* .container {
   max-height: calc(100vh - 200px);
 } */
}
