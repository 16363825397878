.container {
    padding: 24px;
}

.containers {
    padding: 24px;
}

.submit-btn-container { 
    justify-content: flex-end;
    display: flex;
}

.indentions {
    padding: 16px 5px;
}

.payment-box-info {
    background-color: #eaeaea36;
    cursor: pointer;
    padding: 4px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin: 5px;
}


.payment-box-info:hover {
    background-color: #eaeaeabd;
}