.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    width: 100%;
    padding: 0 16px;
    border-bottom: 1px solid #ededf5;
    background-color: #fff;
    z-index: 1;
}

.logo {
    max-width: 125px;
    height: auto;
    padding: 0;
    margin: 5px 0 0 20px;
}

.progress-bar {
    width: 100px;
    margin: 0 5px 0 20px;
}

.progress-bar-credits {
    cursor: pointer;
    font-size: 9px !important;
    margin-right: 10px !important;
}

.flex-space-around {
    display: flex;
    align-items: center;
}

.flex-countdown-align {
    display: flex;
    align-items: center;
    white-space: nowrap;
    /*min-width: 100%;*/
}

.countdown-small {
    background-color: #e8ebff;
    width: 100%;
    padding: 5px 5px 5px 20px;
    justify-content: center;
}

.gradient-btn {
    background: linear-gradient(90deg, #3976EF 0%, #705DFE 90%);
    font-size: 0.675rem !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    height: 24px;
    border-radius: 6px !important;
    margin: 0 25px 0 15px !important;
    /*background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);*/
}

.bolded {
    color: #646464;
    font-weight: 600;
}


@media (max-width: 768px) {
    .progress-bar {
        display: none;
    }
}
