.block {
  border: 1px solid rgba(0,0,0, .4);
  border-radius: 8px;
  padding: 10px 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.blockTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  padding: 0 10px;
}

.blockText {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
}

.grow {
  flex-grow: 1;
}
