:root {
  --container-border-color: #eee;
  --container-border-width: 1.5px;
  --container-border-radius: 12px;
}

.topbars {
  height: 64px;
  background-color: #fff;
  z-index: 2;
}

.topbars:first-child {
  border-right: 1px solid #ededf5;
  display: flex;
  justify-content: center;
}

.main-content{
  /* height:calc(100vh - 64px); */
  box-sizing: border-box;
  flex-wrap: wrap;
}
/* .main-container {
  height: 100vh;
  overflow: hidden;
} */