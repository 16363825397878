.status {
    padding: 2px 3px;
}

.status:first-letter {
    text-transform: uppercase;
}

.statusGreen {
    padding: 2px 12px;
    border-radius: 10px;
    color: #fff;
    background: #8bc34a;
    white-space: nowrap;
}

.statusWarning {
    background: #ff9800;
}

.statusGrey {
    background: #bdbdbd;
}