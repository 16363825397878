.containers {
  /* padding: 24px; */
}

.main-container {
  padding: 24px;
}

.plan-card {
  border-radius: 2px;
  background-color: #eee;
  padding: 15px;
  justify-content: space-between;
}

.calceled {
  /* background-color: rgba(0,0,0, .03); */
}

span.plan-name {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}

button.upgrade-btn {
  font-size: 0.7rem;
  border-radius: 5px;
  text-transform: capitalize;
  padding: 2px 20px;
  min-height: 24px;
}

.creditCard {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (max-width: 899px) {
  .creditCard {
    margin-bottom: 16px;
  }
  button.upgrade-btn {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  button.upgrade-btn {
    width: 100%;
    padding: 8px 20px;
  }
}
