.container {
  /* padding: 24px; */
}

.container .wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  /* padding: 20px; */
  justify-content: center;
  max-width: 1400px;
  /* margin: 0 auto; */
}

.box {
  flex-basis: 23%;
  position: relative;
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  min-width: 200px;
}

.avatarBlock {
  margin-bottom: 16px;
}

.popular {
  border: 1px solid #3976ef;
}

.select {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.selectAmount {
  padding: 0px 10px;
  border-radius: 5px;
  line-height: 1.4;
  border: 1px solid #3976ef;
}

.title {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  color: #707070;
}

.fullPrice {
  color: #3976ef;
  font-size: 23px;
  font-weight: 400;
  text-decoration: line-through;
}

.finalPrice {
  font-size: 28px;
  color: #000;
  font-weight: 600;
}

.month {
  color: #707070;
  font-size: 16px;
}

.renewal {
  display: block;
  position: absolute;
  bottom: -12px;
  font-size: 0.75rem;
  color: #707070;
}

.details {
  display: flex;
  flex-direction: row;
  padding: 10px 5px 10px 0;
  align-items: center;
  color: black;
}

.details span {
  padding: 0px 10px;
  font-size: 14px;
}

.badge {
  position: absolute;
  top: -12px;
  right: 35px;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  background: linear-gradient(227deg, rgb(133, 124, 245) 0%, rgb(42, 123, 244) 100%);
}

.annualSelect {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
}

.annualBadge {
  background: #857cf5;
  background: linear-gradient(227deg,#857cf5,#2a7bf4);
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  margin: 0 5px;
  padding: 0 8px;
}

.annualSelect .annualCta {
  color: #424242;
}

.annualCta.selected {
  background-color: #fff;
  color: #000;
}

.annualCta.selected:hover {
  background-color: #f8f8f8;
}

.headerBlock {
  height: 160px;
  margin-bottom: 32px;
}

.customTitleBox {
  height: 170px;
}

.box .priceBlock {
  margin-bottom: 32px;
}

.listBlock {
  margin-top: 24px;

}

hr.bottomLine {
  margin: 20px 0;
}

hr.bottomLineTrial {
  margin-top: 58px;
}

hr.bottomLineTrialAnnual {
  margin-top: 98px;
}

.ctaBlock {
  margin-top: 24px;
  /*margin-bottom: 40px;*/
}

/*.ctaBtmMargin {*/
/*  margin-bottom: 0;*/
/*}*/

.annualPay{
  font-size: 12px;
  color:#666666;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

/*.supportInfoContainer {*/
/*  position: relative;*/
/*  min-height: 40px;*/
/*}*/

@media screen and (max-width: 1170px) {
  .wrapper {
    gap: 2%;
  }

  .box {
    flex-basis: 49%;
  }
}

@media (max-width: 768px) {
  .wrapper{
    gap: 0;
  }

  .box {
    flex-basis: 100%;
  }

  .avatarBlock {
    margin-bottom: 0;
  }

  .headerBlock, .customTitleBox {
    display: flex;
    align-items: center;
    gap: 10px;
    height: auto;
  }

  .headerBlock {
    margin-bottom: 16px;
  }

  .customTitleBox {
    margin-bottom: 40px;
  }

  .box .priceBlock {
    margin-bottom: 4px;
  }

  .listBlock {
    margin-top: 0;
  }

  .divider {
    display: none;
  }

  .ctaBlock {
    margin-top: 24px;
    margin-bottom: 0;
  }
}
