.center-align{
  position: relative;
  display:flex;
  justify-content: center;
  align-items:center;
}
.price-table-header{
    color:var(--ynt-darkgrey);
    font-size: large;
  }
  .price-table-header h5{
    padding:10px 0;
  }
  .price-table-cell {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .price-table-credits {
    color:var(--ynt-primary);
    font-weight:500;
}
.price-mark {
  margin: 1.3em 0;
  padding: 10px 0;
}