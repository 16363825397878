/* .file-input[type="file"] {
  position: relative;
}

.file-input[type="file"]::file-selector-button {
  width: 136px;
  color: transparent;
}

.file-input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  left: 40px;
  color: #0964b0;
  content: "Upload File";
}

.file-input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

.file-input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

.file-input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

.file-input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}
 */


/* 

body {
 color: #999;
 background-color: #eee;
 font-family: sans-serif;
 font-size: 100%;
} */
/* 
form {
 width: 80%;
 height: 100%;
 margin: 1rem auto;
} */

.file-label {
 position: relative;
 display: block;
 width: 100%;
 font-size: 80%;
 text-align: center;
 border: 2px dashed #ccccccd6;
 padding: 3rem;
}

.short {
  max-width: 500px;
}

.file-label:hover {
 background-color: rgba(255, 255, 255, 0.3);
}

.file-label:active,
.file-label.focus {
 border-color: #09f;
}

.file-input[type=file] {
 position: absolute;
 left: 0;
 top: 0;
 width: 100%;
 height: 100%;
 opacity: 0;
 outline: 0;
 /* border: 1px solid red; */
}

.file-name {
 z-index: 1;
}

.error {
  border: 2px dashed red;
}